var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"tw-pb-24"},[_c('PageHeader',{attrs:{"title":"Overzicht kosten"}}),_c('div',{staticClass:"tw-my-6 tw-mx-auto"},[_c('FormulateForm',{key:_vm.searchKey,staticClass:"tw-px-3 tw-border-b",attrs:{"name":"filterCosts","debounce":""},model:{value:(_vm.filters),callback:function ($$v) {_vm.filters=$$v},expression:"filters"}},[_c('div',{staticClass:"tw-flex tw-flex-wrap tw-gap-4"},[_c('FormulateInput',{attrs:{"type":"autocomplete","auto-complete-type":"companies","multiple":true,"name":"companies","label":"Vennootschappen","placeholder":"Selecteer vennootschappen","input-class":['tw-text-sm tw-border-none tw-h-8 tw-mt-0.5'],"label-class":['tw-text-xs'],"outer-class":"tw-m-0"}}),_c('FormulateInput',{attrs:{"type":"autocomplete","auto-complete-type":"user-offices","multiple":true,"name":"offices","label":"Kantoren","placeholder":"Selecteer kantoren","input-class":['tw-text-sm tw-border-none tw-h-8 tw-mt-0.5'],"label-class":['tw-text-xs'],"outer-class":"tw-m-0"}}),_c('fieldset',{staticClass:"fieldset-split-range !tw-my-0"},[_c('legend',{staticClass:"!tw-text-xs !tw-mb-1"},[_vm._v("Gemaakt op")]),_c('FormulateInput',{attrs:{"type":"date","name":"created_on_after","validation":"bail|optional|date:YYYY-MM-DD","input-class":['tw-text-sm tw-border-none tw-h-8 tw-mt-0.5'],"outer-class":"tw-m-0"}}),_c('FormulateInput',{attrs:{"type":"date","name":"created_on_before","validation":"bail|optional|date:YYYY-MM-DD","input-class":['tw-text-sm tw-border-none tw-h-8 tw-mt-0.5'],"outer-class":"tw-m-0"}})],1),_c('FormulateInput',{attrs:{"type":"select","name":"advance_received","label":"Voorschot ontvangen","options":{
            '': 'Alle',
            true: 'Ja',
            false: 'Nee'
          },"input-class":['tw-text-sm tw-border-none tw-h-8 tw-mt-0.5'],"label-class":['tw-text-xs'],"outer-class":"tw-my-0"}}),_c('FormulateInput',{attrs:{"type":"select","name":"transaction_type","label":"Transactietype","placeholder":"Selecteer transactietype","options":_vm.PROPERTY_TRANSACTION_TYPES,"input-class":['tw-text-sm tw-border-none tw-h-8 tw-mt-0.5'],"label-class":['tw-text-xs'],"outer-class":"tw-my-0"}}),_c('FormulateInput',{attrs:{"type":"select","name":"product","label":"Kost","placeholder":"Selecteer kost","options":_vm.products,"input-class":['tw-text-sm tw-border-none tw-h-8 tw-mt-0.5'],"label-class":['tw-text-xs'],"outer-class":"tw-my-0"}}),_c('FormulateInput',{attrs:{"type":"select","name":"status","label":"Status","placeholder":"Selecteer pandstatus","options":_vm.PROPERTY_STATUSES,"input-class":['tw-text-sm tw-border-none tw-h-8 tw-mt-0.5'],"label-class":['tw-text-xs'],"outer-class":"tw-my-0"}})],1),_c('div',{staticClass:"tw-my-4 tw-text-sm tw-font-semibold tw-flex tw-flex-wrap tw-gap-4"},[_c('router-link',{staticClass:"link hover:tw-text-opacity-80",attrs:{"to":{ query: { filters: JSON.stringify(_vm.filters) } }}},[_c('i',{staticClass:"fas fa-search"}),_vm._v(" Zoeken ")]),_c('a',{staticClass:"link !tw-text-danger hover:!tw-text-opacity-80",on:{"click":_vm.resetFilters}},[_c('i',{staticClass:"fas fa-times"}),_vm._v(" Filters wissen ")])],1)])],1),_c('DataTable',_vm._b({directives:[{name:"show",rawName:"v-show",value:(_vm.showDataTable),expression:"showDataTable"}],attrs:{"loading":_vm.loading,"headers":_vm.headers,"infinite-scroll":true},on:{"infiniteLoad":_vm.infiniteLoad},scopedSlots:_vm._u([{key:"toolbar",fn:function(){return [_c('div',{staticClass:"tw-mb-2 tw-flex tw-justify-end"},[_c('button',{staticClass:"action tw-bg-success tw-mr-0",attrs:{"type":"button","title":"Exporteer de resultaten als csv","disabled":_vm.exporting},on:{"click":_vm.exportAsCsv}},[_c('i',{class:[
              'fas',
              _vm.exporting
                ? 'fa-spinner-third fa-spin'
                : 'fa-external-link'
            ]}),_vm._v(" Exporteren ")])])]},proxy:true},{key:"item-entity_reference",fn:function(ref){
            var item = ref.item;
return [_c('EntityHoverable',{attrs:{"value":item.entity}})]}},{key:"item-created_on",fn:function(ref){
            var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("date-day")(item.created_on))+" ")]}},{key:"item-amount",fn:function(ref){
            var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.currency(item.amount))+" ")]}},{key:"item-discount_amount",fn:function(ref){
            var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.currency(item.discount_amount))+" ")]}},{key:"item-ovab_due_date",fn:function(ref){
            var item = ref.item;
return [(item.transaction_data && item.transaction_data.ovab_due_date)?_c('span',[_vm._v(" "+_vm._s(_vm._f("date-day")(item.transaction_data.ovab_due_date))+" ")]):_vm._e()]}},{key:"item-ovab_passed",fn:function(ref){
            var item = ref.item;
return [(item.transaction_data && item.transaction_data.ovab_passed)?_c('span',[_vm._v(" Voldaan ")]):(item.transaction_data && !item.transaction_data.ovab_passed)?_c('span',[_vm._v(" Niet voldaan ")]):_c('span',[_vm._v(" - ")])]}},{key:"item-entity_deed_date",fn:function(ref){
            var item = ref.item;
return [(item.entity && item.entity.deed_date)?_c('span',[_vm._v(" "+_vm._s(_vm._f("date-day")(item.entity.deed_date))+" ")]):_vm._e()]}},{key:"item-entity_is_deed_signed",fn:function(ref){
            var item = ref.item;
return [(item.entity && item.entity.is_deed_signed)?_c('i',{staticClass:"fas fa-check-circle tw-text-success"}):_vm._e()]}},{key:"item-advance_amount",fn:function(ref){
            var item = ref.item;
return [(item.advance)?_c('span',[_vm._v(" "+_vm._s(_vm.currency(item.advance.amount))+" ")]):_vm._e()]}},{key:"item-advance_received",fn:function(ref){
            var item = ref.item;
return [(item.advance)?_c('div',[_c('i',{class:[
            'fas',
            item.advance.received
              ? 'fa-check-circle tw-text-success'
              : 'fa-clock tw-text-warning'
          ]}),(!item.advance.received)?_c('span',[_vm._v(" ("+_vm._s(_vm.currency(item.advance.balance))+") ")]):_vm._e()]):_vm._e()]}}])},'DataTable',_vm.costs,false))],1)}
var staticRenderFns = []

export { render, staticRenderFns }